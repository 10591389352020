<template>
  <div class="start-page-reg">
    <div class="auth-status__text">
      <span>Войдите в свой личный кабинет или зарегистрируйтесь</span>
    </div>
    <div class="auth-status__btns">
      <router-link :to="{ name: 'Registration' }">
        <Button large>Регистрация</Button>
      </router-link>
      <router-link :to="{ name: 'Auth' }">
        <Button large>Вход</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "AuthStatus",
  components: { Button },
};
</script>

<style lang="scss">
.auth-status {
  &__text {
    font-weight: 600;
    color: var(--text--color);
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    margin-bottom: 15px;
  }
  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    flex-wrap: wrap;
    a {
      flex: 1 0 150px;
    }
    button {
      width: 100%;
    }
  }
}
</style>
